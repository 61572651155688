<template>
  <div class="write-page-heng-container">
    <WriteStrokePage
      :strokeImage="strokeImg"
      :backgroundImageList="bgImgList"
      :flagImageObj="flagImageObj"
      :strokeImgFill="strokeImgFill"
      :strokePinyin="strokePinyin"
      :lesson="lesson"
    />
  </div>
</template>
<script>
import WriteStrokePage from "@/components/Course/CoursePage/WriteStrokePage";

export default {
  data() {
    return {
      strokePinyin: "piědiǎn",

      strokeImg: require("@/assets/img/15-Hanzi-strokes/lesson-14-piedian-grey.svg"),
      strokeImgFill: require("@/assets/img/15-Hanzi-strokes/lesson-14-piedian-red.svg"),
      bgImgList: [
        {
          step: 0,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-14-piedian/dino-1.svg"),
        },
        {
          step: 1,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-14-piedian/dino-2.svg"),
        },
        {
          step: 2,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-14-piedian/dino-3.svg"),
        },
        {
          step: 3,
          img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-14-piedian/dino-4.svg"),
        },
      ],
      flagImageObj: {
        img: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-14-piedian/dino-pending.svg"),
        imgActive: require("@/assets/img/16-GAMES/G19-writing-strokes/lesson-14-piedian/dino-complete.svg"),
        pinyin: "piědiǎn",
        tipImg: require("@/assets/img/15-Hanzi-strokes/lesson-14-stroke-piedian.gif"),
      },
    };
  },
  props: {
    lesson: {
      type: Number,
      require: true,
    },
  },
  components: {
    WriteStrokePage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="game-container">
    <SpeakingSentence :buttonList="buttonList" :gameList="gameList" @initGame="initGame" @showSentence="showSentence" :lesson="lesson"
    :isBlueBackground="true"/>
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      gameList:[
                {
          bgImg:require(`@/assets/img/16-GAMES/G59-click-speaking/lesson-14-background-4.svg`),
          pinyin:"Wǒ jiā yǒu sān kǒu rén: bàba, māma hé wǒ.",
          hanzi:"我家有三口人：爸爸、妈妈和我。",
          isShowSentence:false,
        },

        {
          bgImg:require(`@/assets/img/16-GAMES/G59-click-speaking/lesson-14-background-3.svg`),
          pinyin:"Wǒ jiā yǒu sì kǒu rén: bàba, māma, dìdi hé wǒ.",
          hanzi:"我家有四口人：爸爸、妈妈、弟弟和我。",
          isShowSentence:false,
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G59-click-speaking/lesson-14-background-2.svg`),
          pinyin:"Wǒ jiā yǒu sì kǒu rén: bàba, māma, jiějie hé wǒ.",
          hanzi:"我家有四口人：爸爸、妈妈、姐姐和我。",
          isShowSentence:false,
        },
        {
          bgImg:require(`@/assets/img/16-GAMES/G59-click-speaking/lesson-14-background-1.svg`),
          pinyin:"Wǒ jiā yǒu liù kǒu rén: bàba, māma, gēge, jiějie, mèimei hé wǒ.",
          hanzi:"我家有六口人：爸爸、妈妈、哥哥、姐姐、妹妹和我。",
          isShowSentence:false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G59-click-speaking/lesson-14-menu-4.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G59-click-speaking/lesson-14-menu-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G59-click-speaking/lesson-14-menu-2.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G59-click-speaking/lesson-14-menu-1.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
    props: {
    lesson: {
      type: Number,
      require: true,
    },
    },
  components: {
    SpeakingSentence,
  },
  methods:{
    initGame() {
      this.gameList.forEach((item)=>{
        item.isShowSentence = false
      })
    },
        showSentence(index) {
     this.gameList[index -1].isShowSentence = true
    },
  }
}
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="game-container">
    <SeesawGame
      :chooseHanziList="chooseHanziList"
      :gameInfo="gameInfo"
      :starNum="3"
      :stepsImgList="stepsImgList"
      :answerIdList="[216, 36, 46]"
    />
  </div>
</template>

<script>
import SeesawGame from "@/components/Course/GamePage/SeesawGame";
export default {
  data() {
    return {
      gameInfo: {
        seasawBottomImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/base-kou.svg`),
        baseImg: require(`@/assets/img/16-GAMES/G34-scales/base-scales.svg`),
      },
      stepsImgList: [
        {
          id: 1, // yi
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-er.svg`),
        },
        {
          id: 21,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san.svg`),
        },
                {
          id: 22,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-san.svg`),
        },
        {
          id: 3,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-si.svg`),
        },
        {
          id: 4,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-11/scales-wu.svg`),
        },
        {
          id: 5, // 
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ba.svg`),
        },
        {
          id: 6,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ren.svg`),
        },


        {
          id: 61,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ren-er.svg`),
        },
        {
          id: 621,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ren-san.svg`),
        },
        {
          id: 16,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-er-ren.svg`),
        },
        {
          id: 121,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-er-san.svg`),
        },
        {
          id: 211,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-san-er.svg`),
        },
        {
          id: 216,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-san-ren.svg`),
        },



        {
          id: 36,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-si-ren.svg`),
        },
        {
          id: 35,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-si-ba.svg`),
        },
        {
          id: 63,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ren-si.svg`),
        },
        {
          id: 65,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ren-ba.svg`),
        },
        {
          id: 53,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ba-si.svg`),
        },
        {
          id: 56,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ba-ren.svg`),
        },


                {
          id: 226,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-san-ren.svg`),
        },
        {
          id: 224,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-san-wu.svg`),
        },
        {
          id: 622,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ren-san.svg`),
        },
        {
          id: 64,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-ren-wu.svg`),
        },
        {
          id: 422,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-wu-san.svg`),
        },
        {
          id: 46,
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/scales-wu-ren.svg`),
        },
      ],
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/lesson-14-story-1.svg`),
          wordsPinyin: "sān kǒu rén",
          wordsHanzi: "三口人",
          hanziChooseList: [
            {
              pinyin: "rén",
              hanzi: "人",
              id: 6,
            },
            {
              pinyin: "èr",
              hanzi: "二",
              id: 1,
            },

            {
              pinyin: "sān",
              hanzi: "三",
              id: 21,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/lesson-14-story-2.svg`),
          wordsPinyin: "sì kǒu rén",
          wordsHanzi: "四口人",
          hanziChooseList: [
            {
              pinyin: "sì",
              hanzi: "四",
              id: 3,
            },
            {
              pinyin: "bā",
              hanzi: "八",
              id: 5,
            },
             {
              pinyin: "rén",
              hanzi: "人",
              id: 6,
            },
          ],
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G34-scales/lesson-14/lesson-14-story-3.svg`),
          wordsPinyin: "wǔ kǒu rén",
          wordsHanzi: "五口人",

          hanziChooseList: [
            {
              pinyin: "sān",
              hanzi: "三",
              id: 22,
            },
             {
              pinyin: "rén",
              hanzi: "人",
              id: 6,
            },
            {
              pinyin: "wǔ",
              hanzi: "五",
              id: 4,
            },
          ],
        },
      ],
    };
  },
  components: {
    SeesawGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
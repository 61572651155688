<template>
  <!-- layout-container lesson 14 -->
  <div class="lesson14-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum" :lesson="14">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageB />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 全局白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageB from "./3_SyllabusPage_B.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import RocketGame from "./4_RocketGame.vue";
import DragGamePageOne from "./5_DragGameOne.vue";
import DragGamePageTwo from "./6_DragGameTwo.vue";
import MoveGame from "./7_MoveGame.vue";
import ReviewGame from "./8_ReviewGame.vue";
import ReviewGameTwo from "./9_ReviewGameTwo.vue";
import SpeakingPage from "./10_SpeakingPage.vue";
import RecognitionPage_piedian from "./11_RecognitionPage_piedian.vue";
import PracticePageOne_piedian from "./12_PracticePageOne_piedian.vue";
import PracticePageTwo_piedian from "./13_PracticePageTwo_piedian.vue";
import writePage_piedian from "./14_writePage_piedian.vue";
import PracticeGame from "./15_PracticeGame.vue";
import PracticeGameTwo from "./16_PracticeGameTwo.vue";
import WriteGame_ren from "./17_WriteGame_ren.vue";
import WriteGame_kou from "./18_WriteGame_kou.vue";
import LearnReview from "./19_LearnReview.vue";
import RankMapPageTwo from "./20_RankMapPageTwo.vue";
import ByePage from "./21_ByePage.vue";
export default {
  name: "Lesson14",
  components: {
    SyllabusPageB,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    RocketGame,
    DragGamePageOne,
    DragGamePageTwo,
    MoveGame,
    ReviewGame,
    ReviewGameTwo,
    SpeakingPage,
    RecognitionPage_piedian,
    PracticePageOne_piedian,
    PracticePageTwo_piedian,
    writePage_piedian,
    PracticeGame,
    PracticeGameTwo,
    WriteGame_ren,
    WriteGame_kou,
    LearnReview,
    RankMapPageTwo,
    ByePage,
  },
  mixins: [lessonMixin],
  data() {
    return {
      pageNum: 1,
      // lesson2 中的所有page
      componentsList: {
        LessonPage,
    RankMapPageOne,
    TargetPage,
    RocketGame,
    DragGamePageOne,
    DragGamePageTwo,
    MoveGame,
    ReviewGame,
    ReviewGameTwo,
    SpeakingPage,
    RecognitionPage_piedian,
    PracticePageOne_piedian,
    PracticePageTwo_piedian,
    writePage_piedian,
    PracticeGame,
    PracticeGameTwo,
    WriteGame_ren,
    WriteGame_kou,
    LearnReview,
    RankMapPageTwo,
    ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson14-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background-color: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

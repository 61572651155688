<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/background-room.svg"),
      gameIndex: 9,
      strokeImgList: [
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-rocket.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-rocket-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-lamp.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-lamp-found.svg"),
        },
       {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-ship.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-ship-found.svg"),
        },
       
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-clock.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-clock-found.svg"),
        },
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-picture.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-picture-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-piedian.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-14-1/stroke-piedian-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div class="game-container">
    <DragGame
      :envelopeList="envelopeList"
      :mailboxList="mailboxList"
      :dragNum="dragNum"
      @randomOrder="handleRandomOrder"
      @socketRandom="handleSocketRandom"
    />
  </div>
</template>

<script>
import DragGame from "@/components/Course/GamePage/DragGame";
export default {
  data() {
    return {
      dragNum: 3,
      envelopeList: [
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-14-envelope-ge.svg"),
          isDispear: false,
          key: 1,
          index: 1,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-14-envelope-ren.svg"),
          isDispear: false,
          key: 2,
          index: 2,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/lesson-14-envelope-he.svg"),
          isDispear: false,
          key: 3,
          index: 3,
        },
      ],
      mailboxList: [
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
          pinyin: "kǒu",
          hanzi: "口",
          value: 1,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
          pinyin: "rén",
          hanzi: "人",
          value: 2,
        },
        {
          picture: require("@/assets/img/16-GAMES/G10-mailbox/mailbox.svg"),
          pinyin: "hé",
          hanzi: "和",
          value: 3,
        },
      ],
    };
  },
  components: {
    DragGame,
  },
  methods: {
    handleSocketRandom(value) {
      this.envelopeList = value;
    },
    handleRandomOrder() {
      this.envelopeList.sort(function () {
        return Math.random() - 0.5;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











